<template>
	<div class=" d-flex flex-row">
		<div
    v-if="imageurl" 
    data-toggle="tooltip" 
    data-placement="top" 
    title="" 
    class="thumbnail company-logo btn-file d-flex mr-3" 
    style="position: relative;" 
    data-original-title="Click to update picture"
    :class="{hide:cropping==true, ' B500-b':!organization.logo, 'NO-b': organization.logo}">

    <img :src="imageurl" @error="imageonerror($event)">
    </div> 
    <div
    v-else
    data-toggle="tooltip" 
    data-placement="top" 
    title="" 
    class="thumbnail company-logo btn-file d-flex mr-3" 
    style="position: relative; border-radius:4px; border: 1px solid #2e477b;" 
    data-original-title="Click to update picture"
    :class="{hide:cropping==true, ' B500-b':!organization.logo, 'NO-b': organization.logo}">

    <img v-if="organization.name && organization.name != ''" width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase() +'.png'" @error="imageonerror($event)">
    </div>
  <span 

    class=" company-logo d-flex mr-3" 
    href="#" 
    v-if ="cropping">
     <i class="fa fa-circle-o-notch fa-spin m-auto" style="font-size: 30px;"/>

  </span> 


  <b-button v-if="myrole && myrole.toUpperCase() == 'ADMINISTRATOR'" id ="addimgbtn" class ="my-auto" @focus="onblurinput($event)" @click.prevent="orgImageuploaderclick()" >Upload new icon</b-button>
  
<div 
class="progress" 
v-if="uploadedsize > 1 && uploadedsize < 100">
<div 
id="uploader" 
class="progress-bar" 
role="progressbar"  
v-bind:style="'width: ' + uploadedsize + '%'" 
aria-valuenow="25" 
aria-valuemin="0" 
aria-valuemax="100"></div>
</div> 

<input 
style="display: none;" 
type="file" 
name="uploadedprofileimage" 
accept="image/*" 
@change="onfileselected" 
@blur="onblurinput"
ref="orgImageuploader" 
@click="fileclicked"/>
</div>
</template>

<style lang = "scss">

 
</style>
<script type="text/javascript"></script>

<script>

	import avatar from '@/views/tools/avatar'

	import AWS from 'aws-sdk'
	import fs from 'fs'


  const ID = process.env.VUE_APP_CLIENT;
  const SECRET = process.env.VUE_APP_CRET;



	// The name of the bucket that you have created
	const BUCKET_NAME = 'floweimage';
	AWS.config.update({region: 'eu-west-3'});

	const s3 = new AWS.S3({
		accessKeyId: ID,
		secretAccessKey: SECRET,
		apiVersion: '2006-03-01', 
		region: 'eu-west-3'
	});

	export default{
		name: "imagewithupload",
		props:{
     myobjectivesid:String,
     imageurl: String,
     myrole: String,
     organization: Object
   },
   data(){
     return{
      cropping: false,
      name:"",
      uploaderror:"",
      uploadedsize : 0,
      status:"idle", 
      updated:false, 
      success:false, 
      update:""
    }
  },
  mounted(){				
  },

  methods: {

    orgImageuploaderclick(){

      this.$refs.orgImageuploader.click()
      this.$emit('onupload')

    },

    imageonerror( el ){

      el.currentTarget.src = '/static/img/names/org.png'
    },

    defaultimg( orgname ){

      const _ = this
      alert( ((_imgError) ? 'yes' : 'no') ) 

      var firstletter = orgname.charAt(0).toLowerCase()
      var alphanum = firstletter.match(/^[0-9a-z]+$/)


      if( alphanum ){
        firstletter = "x"
      }

      var img = '/static/img/names/' + orgname.charAt(0).toLowerCase() +'.png'

      return img

    },

   fileclicked(){

    $('[data-toggle="tooltip"]').tooltip('hide')

  },

   onblurinput(){

    setTimeout(function(){
      
        $("#addimgbtn").blur(); 
    },100)
  },
  
  onfileselected( event ){

 

    var file = event.target.files[0];      
    let formData = new FormData();
    formData.append('file', file);  

    var validfiletype = false
    var newfile = formData.get('file');
    var ext = ""
    var uploadfailed = 0

    if( newfile.type.indexOf("jpeg") > -1 ){
      validfiletype = true
      ext = ".jpeg"
    }
    else if( newfile.type.indexOf("png") > -1 ){
      validfiletype = true
      ext = ".png"
    }
    else if( newfile.type.indexOf("gif") > -1 ){
      validfiletype = true
      ext = ".gif"
    }else{
      uploadfailed++
      validfiletype = false
    }

    if( validfiletype ){

      $('[data-toggle="tooltip"]').tooltip('hide')

      this.uploaderror = ''
      this.cropping = true

      var md5 = require('md5');

      let self = this

      this.profilepicupdate = false
      this.status = "uploading"     


      var fr = new FileReader();
      var image = new Image();

      image.onerror = function() {
        self.uploaderror = 'invalid'
        self.cropping = false
      };

      image.onload = function() {


        if(this.width) {
          /**/

          fr.onload = function( e ){

            self.croppingimg = e.target.result; 

            if( uploadfailed == 0 ){

              var newfilename = md5(newfile.name + nowstring) + ext

              var bucketpath = "https://s3-eu-west-3.amazonaws.com/floweimage/"

              var nowstring = new Date().toString()
              const params = {
                Bucket: 'floweimage',
                ACL : 'public-read',
                    Key:  newfilename, // File name you want to save as in S3
                    Body: file
                  };

                //self.imageurl = ""


                s3.upload(params, function(err, data) {

                  if (err) {
                    throw err;
                  }

                  $.getJSON( "https://profilepictures2.flowe.io/?resize=yes&i=" + newfilename, function(data){
                    //self.froalaconfig.imageUploadToS3 = data

                    if( data.ok ){

                      var newlocation = bucketpath + data.filename

                      //self.imageurl =  newlocation

                      self.cropping = false

                      // update org data with new logo

                      //self.doupdate( 'logo' )

                      self.$emit('onimageuploadsuccess', newlocation)


                    }
                    //console.log( data, "onupload")
                  }).fail(function() {
                    //self.uploaderror = 'uploadfailed'
                    self.$emit('onimageuploadfailed', 'uploadfailed')
                  })              

              //console.log(`File uploaded successfully. ${data.Location}`);
            });

              }else{
              //self.uploaderror = 
              self.$emit('onimageuploadfailed', 'invalid')
            }

          }

          fr.readAsDataURL(file);

        }else{
          self.uploaderror = 'invalid'
          self.cropping = false
        }
      };

      // load the file into an image to check if its an actual image
      image.src = URL.createObjectURL(file);


    }else{
          //this.uploaderror = 'invalid'
          this.$emit('onimageuploadfailed', 'invalid')
        }
      },


    },
    computed: {
    },
    components: { avatar }		
  }
</script>