<template>
	<div class ="d-flex flex-row" v-on ="listeners">
		<div class="company-logo d-flex" :class ="{ 'NO-b2': organization.logo, 'current': current, 'inlist': inlist}">
	        <img width="40" height="40" :src = "organization.logo" v-if="organization.logo" @error="imageonerror($event)">
	        <img width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" v-if="organization.name && ( !organization.logo || organization.logo == '' )" @error="imageonerror($event)">       
	    </div>
	    <div v-if = "hasName" :class ="textClass" class=" logoname mr-2 my-auto overflow-string w-100 " >
            <span class =" orgname"> {{organization.name || 'Flowe'}} </span>
        </div>
	</div>
</template>
<style type="" lang = "scss" scoped>
@import '@/scss/abstracts/variable.scss';
	.orglogowithname{

		width: 38px;
		height: 38px;
		display: flex;
	}
	.logoname{
		margin-left:  5px;
	}
	.inlist{
		margin-left: 3px;
		margin-right:3px;
		border: solid 1px transparent;
		border-radius: 4px;
		padding:1px;
		&.company-logo{
			min-width: 42px;
			width: 42px;
			height: 42px;
			
		}
		&.current{
			border-color: $B500;
		}
	}
</style>
<script type="text/javascript"></script>

<script>
	export default{
		name: "base-org-logo",
		props:{
			organization:{
				type:Object,
				default: () => {}
			},
			current:{
				type:Boolean,
				default:false
			},
			hasName:{
				type:Boolean,
				default:true
			},
			textClass:{
				type:String,
				default:'hide-text'
			},
			inlist:{
				type:Boolean,
				default:false
			}
		
		},
		methods:{

			imageonerror( el ){

		      el.currentTarget.src = '/static/img/names/org.png'
		    }


		},
		computed: {
			listeners(){

				return{
					...this.$listeners,
					input: event =>
					this.$emit('input', event.target.value),
				}
			}
		}
	}
</script>