<template>

	<div>
			
		<TopBar :columnnumber = "2" hidedropdown simple>
			<template v-slot:title>
				Organizations
			</template>
			
		</TopBar>

			<main class="main-wrapper cockpitview" style= "margin-top:21px;">
				<div class="cockpits">

					<h4 class="alternate text-left mb-0"><span> Organizations I administer </span></h4>
					
					<div class="row m-0">

						


						<div class="orgcard-wrapper" v-for="(organization, index) in admingroup" :key="index">
							<a @click="selectorg(organization)">
							<div class="my-3 orgcard card dashboarditem" :class="{ 'current' : orgkey == organization.key }">
					        	<div class="d-flex align-items-center accent2">					        		
					        		<div class="company-logo d-flex" :class ="{' B500-b':!organization.logo, 'NO-b': organization.logo }">
					        			<img width="40" height="40" :src = "organization.logo" v-if="organization.logo" @error="imageonerror($event)">
					        			<img width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" @error="imageonerror($event)" v-else>
                  					</div>
					        	</div> 
					          	<div class="align-self-center w-auto contentbox">
					          		<span class="title"> {{ organization.name }} </span>
					          	</div>
						    </div>
							</a>
						</div>

						<div class="orgcard-wrapper">
							<a href="#" @click="opencreateorgmodal()">
							<div class="my-3 orgcard-add card dashboarditem"> 
					        	<div class="text-center p-3">
									<i aria-hidden="true" class="fa fa-plus-circle"></i>
									<div class="link">Add new organization</div>
								</div>
						    </div>
						</a>
						</div>

					</div>

				</div>

				<div class="cockpits">
					
					<h4 class="alternate text-left mb-0"><span> Organizations I am a member of </span></h4>

					<div class="row m-0">

						<div class="brick col-lg-4 col-sm-6 p-0 clearfix mb-3 mt-2" v-if="membergroup.length==0">
							<span class=" "> - there are no organizations in this section - </span>
						</div>

						<div class="orgcard-wrapper" v-for="(organization, index) in membergroup" :key="index" v-else>
							<a @click="selectorg(organization)">
							<div class="my-3 orgcard card dashboarditem" :class="{ 'current' : orgkey == organization.key }">
					        	<div class="d-flex align-items-center accent2">
					        		<div class="company-logo d-flex" :class ="{' B500-b':!organization.logo, 'NO-b': organization.logo }">
					        			<img width="40" height="40" :src = "organization.logo" v-if="organization.logo" @error="imageonerror($event)">
                  						<img width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" @error="imageonerror($event)" v-else>
                  					</div>
					        	</div> 
					          	<div class="align-self-center w-auto contentbox">
					          		<span class="title"> {{ organization.name }} </span>
					          	</div>
						    </div>
							</a>
						</div>

					</div>

				</div>

				<div class="cockpits">

					<h4 class="alternate text-left mb-0"><span> Inactive organization I administer </span></h4>
					
					<div class="row m-0"> 
						<div class="brick col-lg-4 col-sm-6 p-0 clearfix mb-3 mt-2" v-if="disabledgroup.length==0">
							<span class=" "> - there are no organizations in this section - </span>
						</div>

						<div class="orgcard-wrapper" v-for="(organization, index) in disabledgroup" :key="index" v-else>
							<a a @click="selectorg(organization)" class="card-block clearfix">
							<div class="my-3 orgcard card dashboarditem" :class="{ 'current' : orgkey == organization.key }">
					        	<div class="d-flex align-items-center accent2">
					        		<div class="company-logo d-flex" :class ="{' B500-b':!organization.logo, 'NO-b': organization.logo }">
					        			<img width="40" height="40" :src = "organization.logo" v-if="organization.logo">
                  						<img width="40" height="40" :src = "'/static/img/names/' + organization.name.charAt(0).toLowerCase()+'.png'" @error="imageonerror($event)" v-else>
                  					</div>
					        	</div> 
					          	<div class="align-self-center w-auto contentbox">
					          		<span class="title"> {{ organization.name }} </span>
					          	</div>
						    </div>
							</a>
						</div>

					</div>

					

				</div>
			</main>

		</div>

	
</template>

<style lang = "scss" scoped>

	@import '@/scss/abstracts/variable.scss';

	.orgcard-wrapper{

		max-width: 360px;
  		padding: 0 20px 0 0;

	}

	.orgcard.item{
		border: 1px solid $B500 !important;
	}

	.orgcard.current{
		border: 1px solid $B500 !important;
	}

	.orgcard{
		height: 150px; 
		width: 300px;

		
		border-top: none;



		.accent2{
			height: 56px; 
			background-color: $B500; 
			border-bottom: none;
			border-top-left-radius: 4px; 
			border-top-right-radius: 4px;
			border-bottom-left-radius: 0; 
			border-bottom-right-radius: 0;
		}

		.company-logo{
			margin: auto;
			background-color: #ffffff;
		}

		.contentbox{
			height: 94px; 
			/*border: 1px solid #2e477b; */
			border-top: none;
			border-bottom-left-radius: 4px; 
			border-bottom-right-radius: 4px;
			border-top-left-radius: 0; 
			border-top-right-radius: 0;

			.title{
				word-break: break-wprd; 
				display: block; 
				margin: auto; 
				font-size: 15px; 
				color: $B500; 
				text-align: center; 
				padding-top: 12px;
			}

		}

	}

	.orgcard-add{
		
		height: 150px; 
		width: 300px;
		border: 1px solid $N200; 
		border-radius: 4px; 
		color: $B500;

		.fa-plus-circle{ font-size: 6rem; }
		.link{ font-size: 15px; text-align: center; }
	}

</style>
<script type="text/javascript"></script>

<script>
	import {TopBar} from '@/components/topbar'
	import {bus} from '@/main'

	export default{
		name: "organizationsoverview",
		data(){
			return{
				organizations : [],
				admingroup : [],
				membergroup : [],
				disabledgroup : []
			}
		},
		components:{
      
      		TopBar
      	},
		created(){

			},
		mounted(){

			bus.$off('refreshorgpage')
			bus.$on('refreshorgpage', this.getallorganizations)

			document.title ='Organizations | Flowe'

			$('#preloader').addClass('hide')


			

			this.getallorganizations()
		},

		methods: {

			imageonerror( el ){

      el.currentTarget.src = '/static/img/names/org.png'
    },
    
			selectorg(org){



				if( this.selectedorg._id != org._id){

					let self = this

			    	this.$store.dispatch('setCurrentOrg',{ organization:org }).then((response)=>{
			        	var str1 = 'Changed organization'
				        var str = 'You are now signed in to the ' + org.name + ' organization.'

				        bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:false, sup:''})

				        self.$router.push( '/'+org.key+'/projects?select' )
				        self.$emit('close')
			        })

			     }




			     if( this.selectedorg._id == org._id){
			     	this.$router.push( '/'+org.key+'/projects' )
				    this.$emit('close')
			     }


			},

			opencreateorgmodal(){
	         //3OmvRLgDp

	          //bus.$emit('showorgsettings');
	          bus.$emit('poporgsettings')
	        },

			getallorganizations(){

				let self = this

				this.$store.dispatch('fechtAllOrganizations', {token: this.$store.getters.token }).then(( response ) => {


					self.organizations = response.data.organizations

					self.admingroup = response.data.grouped.administrator
					self.membergroup = response.data.grouped.member
					self.disabledgroup = response.data.grouped.disabled
				})
			}
		},
		computed: {

			listorganizations(){
				return  this.$store.state.organizations.listorganizations
			},

			selectedorg(){
				return  this.$store.state.organizations.currentorganization
			},
			orgkey(){
		      return this.$route.params.orgname
		    },
		},
	
	}
</script>