window.$ = window.jQuery = require('jquery');


var toolbarButtonsBig = {    

		        "moreText": {
		        buttons: [ 'paragraphFormat', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'textColor', 'backgroundColor', 'clearFormatting'],
		        align: 'left',
		        buttonsVisible: 3
		        },
		        "moreParagraph": {
		        buttons: ['align','formatOL', 'formatUL', 'outdent', 'indent'],
		        align: 'left',
		        buttonsVisible: 2
		        },
		        "moreRich": {
		          buttons: [ 'insertImage', 'insertFile', 'insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertHR', 'insercheck', 'insertimes','insertwarning', 'insertinformation'],
		          align: 'left',
		          buttonsVisible: 1
		        },
		        "moreMisc": {
		          buttons: [  'undo', 'redo', 'fullscreen', 'print', 'html','exportword', 'chatGPT'],
		          align: 'right',
		          buttonsVisible: 2
		        }
		      }


 var toolbarButtonsMini = {    
        "moreText": {
        buttons: [  'insertImage', 'paragraphFormat','bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'clearFormatting'],
        align: 'left',
        buttonsVisible: 2
        },
        "moreParagraph": {
        buttons: ['outdent', 'indent', 'align','formatOL', 'formatUL', 'undo','redo'],
        align: 'left',
        buttonsVisible: 0
        },
        "moreRich": {
          buttons: [   'insertFile', 'insertLink', 'emoticons','insertHR','insercheck', 'insertimes','insertwarning', 'insertinformation'],
          align: 'left',
          buttonsVisible: 0
        },
        "moreMisc": {
          buttons: ['fullscreen', 'exportword', 'chatGPT'],
          align: 'left',
          buttonsVisible: 1
        }
        
      }


  var toolbarButtons;

  // var froala = {};
  
 if( window.innerWidth >= 578 ){

 	toolbarButtons = toolbarButtonsBig

 } else {
 	toolbarButtons = toolbarButtonsMini
 	
 }

 var EVENTS = {
    'initialized': function () {
      // Do something here.
      // this is the editor instance.
      console.log('froalainit');
    },

      
    'paste.before': function (original_event) {
      // Do something here.
      // this is the editor instance.
      console.log(this, original_event);
    },
    //   //link = "https://profilepictures.flowe.io/?i=" + key
  
    // },

    'table.inserted': function (table) {
      window.$(window.$(table).find('td')[0]).css("width","50")
    },

  }


var froalaconfig = {
  state:{

    //fileUploadParam: 'file_param',
    fileUploadToS3 : true,
    // Set the file upload URL.
    //fileUploadURL: 'http://localhost:8081/Tools/Uploadconfirm',
    // Additional upload params.
    // Set request type.
    //fileUploadMethod: 'POST',


    toolbarSticky: true,
    //iconsTemplate: 'font_awesome',
    imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'rotateImg'],
    pasteDeniedAttrs: ['style', 'class', 'id', 'width', 'height'],
    htmlAllowedTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span', 'img', 'ul', 
        'li', 'ol', 'br', 'table', 'tr', 'td', 'tbody' , 'thead', 'hr','strong', 'em', 'i', 'u', 'a' ],
    imageInsertButtons: ['imageBack', '|', 'imageUpload'],
    imageUpload: true,
    requestWithCORS: false,
    heightMin:400,
    heightMax :'none',

      imageUploadToS3:{
        bucket: 'floweimage',
        region: 's3-eu-west-3',
        keyStart: 'uploads',
        params: {}
      },
      
      key: '0BA3jA6D7C5F5gA-9mnE2fmmtaG4ohtA2B2C2B1C5F1F1F1h3B9==',
      attribution: false,
      events: EVENTS,
      paragraphFormat:{
        N: 'Paragraph',
        H1:'Heading 1',
        H2:'Heading 2',
        H3:'Heading 3',
        H4:'Heading 4',
        H5:'Heading 5',
      },
      linkAlwaysBlank:true,
      listAdvancedTypes: false,
      wordPasteModal: false,
      wordPasteKeepFormatting: false,
      toolbarButtons : toolbarButtons,
      tableStyles: {
        'fr-no-border': 'No border',
        'fr-alternate-rows': 'Alternate rows'
      }
  },

  mutations:{
    SET_EVENTS(state, key, event){
      state.event = Object.assign(state.event,{[key]:event})
    },

    SET_IMAGEUPLOADTOS3(state, data){
      state.imageUploadToS3 = data
      state.fileUploadToS3 = data
    }


  },
  actions:{},
  getters:{},
}

export default froalaconfig


